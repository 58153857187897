import { defineStore } from "pinia";

export const useSaleStore = defineStore("saleStore", {

  state: () => ({

    http: useNuxtApp().$http,


    query: {
      search: "",
      salesman_id: null,
      customer_id: null,
      saved_at_start: null,
      saved_at_end: null,
      statuses: [],
      status: null,
      page: 1,
    },

    sales: {
      data: []
    },

    sales_totals: {
      net_total_sum: 0,
      net_total_avg: 0,
      count: 0,
    },

    status_options: []


  }),

  actions: {

    async getSales() {


      if (!useAcl().can("viewAllSales")) {
        this.query.salesman_id = useAcl().salesman()?.id
      }



      const data = await this.http.index("sale/sale", this.query);

      this.sales = data.sales

      this.sales_totals = data.sales_totals

      this.status_options = data.status_options

    },

    async getSale(id) {

      const data = await this.http.show("sale/sale", id);

      return data.sale
    },

    async deleteSale(id) {

      await this.http.destroy("sale/sale", id);

    },

  },
});
